import React from "react"
import quoteIcon from "../images/quote_icon.svg"

const StaticReviews = ({ reviews }) => {
  return (
    <div className="border-b border-t border-gray-100 flex flex-col justify-between md:flex-row">
      {reviews.map((review, index) => {
        const borderR = index === 0 ? ` border-b md:border-b-0 md:border-r` : ``
        return (
          <div
            key=""
            className={`bg-white relative flex align-middle flex-col w-full md:w-1/2 py-10 md:py-20 px-12 ${borderR}`}
          >
            <div className="w-full mb-8 text-center">
              <img
                className="inline-block mx-auto w-10"
                src={quoteIcon}
                alt="Quote"
              />
            </div>
            <p className="inline-block text-xl font-bold text-center mx-auto">
              “{review.review}”
            </p>
            {review.name && (
              <span className="inline-block text-lg md:text-xl w-full text-center mx-auto mt-6">
                {review.name}
              </span>
            )}
          </div>
        )
      })}
    </div>
  )
}

export default StaticReviews
