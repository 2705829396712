/** @jsx jsx */
import { useState } from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import { jsx } from "@emotion/core"
import { animated, useSpring } from "react-spring"
import config from "../config/index"

const SpanishSunResearch = ({ data }) => {
  const [isLinkActive, setLinkActive] = useState(false)

  const [isLinkActiveOne, setLinkActiveOne] = useState(false)

  const zoom = useSpring({
    transform: isLinkActive ? `scale(1.05)` : `scale(1)`,
  })

  const zoomOne = useSpring({
    transform: isLinkActiveOne ? `scale(1.05)` : `scale(1)`,
  })

  return (
    <div class="border-t border-gray-100 px-5 py-12">
        <h3 class="uppercase tracking-widest text-2xl text-center font-heading text-heading pb-12">Research for Shadows over the Spanish Sun novels</h3>

        <div class="flex flex-col lg:flex-row">
            <div class="w-full lg:w-1/2 mb-4 lg:mb-0 lg:mr-2 overflow-hidden lg:pl-10 lg:pr-5">
                <p class="pb-5">Research plays a large part in my novels, and in ‘Shadows Over the Spanish Sun,’ a love story, set in the controversial and tragic era of the Spanish Civil War, it was more important than ever to read copiously before attempting to start the first draft. I am not a historian and would never try to be one, but you have to be familiar with the subject you are writing about and have a feeling for the country and the people.</p>
                <p class="pb-5">My material was varied, from modern novels, to Paul Preston one of the greatest writers on the Spanish Civil War. I was carried away for a second time by Ernest Hemingway’s ‘For whom the Bell tolls,’ and Laurie Lee’s ‘A moment of War.’ There were or course several more. But it was not just books, it was photographs, old newspaper cuttings, letters. All these intimate and immediate memories gave me so much to work with. Who could look at some of the most brutal and stark photographs ever taken without being incredibly moved. Robert Capa’s much debated image of the ‘Fallen Soldier,’ comes to mind, but his girlfriend Girda Taro’s extraordinary and iconic images brought tears to my eyes.  My heroine Valeria or Val Saint as she was later known, was a photo journalist in the Civil War and subconsciously, I believe I may have modelled her on Girda.</p>
                <p class="pb-5">I read the poetry of Federico Garcia Lorca, who was shot and killed by Nationalist Militia in 19th August 1936.</p>
                <p class="pb-5">Hot southern sands</p>
                <p class="pb-5">Yearning for white camellias</p>
                <p class="pb-5">Weeps arrow without target.</p>
                <p class="pb-5">This poem speaks to me of Spain and grief and loss.</p>
                <p class="pb-5">And then of course I was able to draw on my bank of memories.</p>
                <p class="pb-5">When I was fourteen, my father had a riding accident resulting in serious head injuries. Fortunately, he recovered, but was unable to continue his career as a surgeon. With the new strictures on his life he decided to pack up our farm in Oxfordshire, sell the herd of Friesian cows, the horses and move to Mallorca with my mother, our two golden retrievers and our cat, Cleopatra. It was a terrible blow at the time, but soon the holidays were something I longed for.</p>
            </div>
            <div class="w-full lg:w-1/2 mb-4 lg:mb-0 lg:mr-2 overflow-hidden z-auto lg:pr-10 lg:pl-5">
                <p class="pb-5">Six kilometers from the sea, the farmhouse near Pollensa, was a refuge where I could leave my teenage troubles behind and walk the olive groves with my father, go to the markets with my mother and help Ramon pick the oranges. It was the dignified, reserved gardener who finally told me about Mallorca’s role in the Spanish Civil War. He had been a child when the Nationalists took over Mallorca, and in August 1936 his father was part of the Republican’s attempt to capture it back. A month later with the help of Mussolini’s Italian Air force, the battle was won by the Nationalists and they had consolidated their grip on the island. After their victory the Italians built a seaplane base at Puerto Pollensa and used it as their headquarters to attack Republican shipping.</p>
                <p class="pb-5">Despite exhaustive attempts to find out what happened, Ramon’s father was never found. It was assumed he was just one of thousands to be massacred by the Nationalists. Even today mass burial sites are being discovered, it is hoped perhaps Ramon’s descendants will finally learn the truth.</p>
                <p class="pb-5">Many of the characters in my novel were drawn from people I once knew and people I still know today, not only in Spain but in England. For many years I competed in dressage at advanced level and one of the greatest dressage riders alive today is my very good friend. Olympic gold medalist, Carl Hester. Quite definitely he inspired Leonardo, not his character but his extraordinary ability on a horse. In the book, Leonardo, is a young caballero with an exceptional affinity with horses. He rescues Saturno a colt from a life of hardship, and develops a bond that will survive throughout the Spanish Civil War and for years afterwards. 
    Valeria, the love of Leonardo’s life, is a character that grew entirely from my imagination, but she is someone I would have loved to have known.
    The gardener from my youth, Ramon, inspired Leonardo’s trusted servant and friend. </p>
    <p class="pb-5">In normal times I would try to visit every town and village in the book, but these were not normal times. We were, and still are, facing the pandemic which was taking and disrupting our lives.</p>
            <p class="pb-5">My last trip to Andalucía was in October 2019, with my husband, rediscovering Seville and Granada, but my planned stay in the Monasterio La Cartuja, in Cazalla de la Sierra, plus a </p>
            </div>
        </div>
        <Img
        className="block"
        css={{
          height: "100%",
          maxWidth: "900px",
          margin: '80px auto 40px',
        }}
        fluid={data.spanishReview.childImageSharp.fluid}
      />
    </div>
  )
}

export default SpanishSunResearch
