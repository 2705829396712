/** @jsx jsx */
import { useState } from "react"
import Img from "gatsby-image"
import { jsx } from "@emotion/core"
import { animated, useSpring } from "react-spring"
import config from "../../config"

const HeroBook = ({ data }) => {
  const [isLinkActive, setLinkActive] = useState(false)
  const [isThumbActive, setThumbActive] = useState(false)

  const zoom = useSpring({
    transform: isLinkActive ? `scale(1.05)` : `scale(1)`,
  })
  const animationThumb = useSpring({
    transform: isThumbActive ? `scale(1.05)` : `scale(1)`,
  })

  const up = useSpring({
    to: [{ transform: "translateY(0)" }],
    from: { transform: "translateY(100px)" },
  })
  return (
    <div>
      <div
        className="relative"
        css={{
          height: "500px",
        }}
      >
        <div className="absolute h-full w-full overflow-hidden">
          <Img
            className="object-cover h-full w-full"
            fluid={data.spanishSun.childImageSharp.fluid}
          />
        </div>
        <div
          css={{
            position: "absolute",
            width: "100%",
            height: "100%",
            display: "block",
            top: "0",
            left: "0",
            backgroundColor: "rgba(254, 87, 28, 0.95);",
          }}
        ></div>
        <div className=" w-full h-full justify-start align-top flex flex-col">
          <h1 className="z-50 w-full text-5xl text-center text-white mt-20 font-heading">
            Shadows Over the Spanish Sun
          </h1>
          <div className="z-50 flex flex-row justify-center mt-4">
            <a
              href={config.buylinks.spanishLink}
              rel="nofollow noopener"
              target="_blank"
              className="inline-block text-white hover:text-black z-50"
              css={{
                transition: "0.7s all",
              }}
            >
              Buy now >
            </a>
          </div>
        </div>
      </div>
      <animated.div
        style={up}
        className="z-50 flex flex-row justify-center  -mt-64 mx-auto w-1/2 md:w-1/4 h-auto z-50"
      >
        <animated.a
          href={config.buylinks.spanishLink}
          rel="nofollow noopener"
          target="_blank"
          className="inline-block w-full h-full"
          style={animationThumb}
          onMouseEnter={() => setThumbActive(true)}
          onMouseLeave={() => setThumbActive(false)}
        >
          <Img
            className="object-cover z-50 w-full h-full"
            css={{
              boxShadow: "-3px 3px 5px rgba(0 ,0, 0, 0.15);",
            }}
            fluid={data.spanishSunThumb.childImageSharp.fluid}
          />
        </animated.a>
      </animated.div>
      <animated.div
        style={up}
        className="max-w-3xl text-center mx-auto mt-16 text-xl md:text-2xl px-8 md:px-0 mb-8 md:mb-0 text-secondary font-semibold"
      >
        <p>
        A country in the shadow of war. A love that burns through the decades...
        </p>
      </animated.div>
      <animated.div
        style={up}
        className="hidden lg:block my-16 w-0 mx-auto border-r border-gray-100 h-16"
      ></animated.div>
      <div className="max-w-3xl text-center mx-auto px-8 md:px-0">
        <p className="mb-8 inline-block">
        Mia Ferris's heart has always belonged in Spain. Every childhood summer was spent at her grandfather's hacienda, riding together amongst the olive trees or listening to his stories of the past. So when she learns that he has fallen from his horse, she knows that she belongs by his bedside - even if it means leaving behind her life in London, and her new fiancé.
        </p>
        <p className="mb-8 inline-block">
        But as Leonardo fights for his life, and Mia to save the family home from financial ruin, secrets begin to emerge that tell a different story of the past - a terrible history that begins with a boy running for his life over the Andalusian hills, and ends with a forbidden love that only war can destroy...
        </p>
        <p className="mb-8 inline-block">
        As Mia untangles the passions and betrayals of the past, everything she thought she knew is turned upside down. Can she heal the wounds of the past, and face the truth of her own heart?
        </p>

        <p className="inline-block font-semibold">
        A sweeping novel of passionate love, betrayal and redemption, set against the turmoil and tragedy of the Spanish Civil War.
        </p>
      </div>
      <div className="pb-20">
        <animated.div
          style={zoom}
          onMouseEnter={() => setLinkActive(true)}
          onMouseLeave={() => setLinkActive(false)}
          className="w-40 mt-20 h-40 text-center mx-auto flex justify-center align-middle flex-col text-white"
          css={{
            borderRadius: "50%",
            backgroundColor: "rgb(254, 87, 28);",
          }}
        >
          <a
            href={config.buylinks.spanishLink}
            rel="nofollow noopener"
            target="_blank"
            css={{
              display: "flex",
              flexFlow: "column",
              height: "100%",
              width: "100%",
              verticalAlign: "middle",
              justifyContent: "center",
            }}
          >
            <span className="inline-block">Buy now ></span>
          </a>
        </animated.div>
      </div>
    </div>
  )
}

export default HeroBook
