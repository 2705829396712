/** @jsx jsx */
import { useState } from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import { jsx } from "@emotion/core"
import { animated, useSpring } from "react-spring"
import config from "../config/index"

const SpanishBottom = ({ data }) => {
  const [isLinkActive, setLinkActive] = useState(false)

  const [isLinkActiveOne, setLinkActiveOne] = useState(false)

  const zoom = useSpring({
    transform: isLinkActive ? `scale(1.05)` : `scale(1)`,
  })

  const zoomOne = useSpring({
    transform: isLinkActiveOne ? `scale(1.05)` : `scale(1)`,
  })

  return (
    <div class="flex flex-col md:flex-row">
      <div
        className="relative w-full md:w-1/2 mb-4 md:mb-0 md:mr-2 overflow-hidden z-auto"
        css={{
          height: "630px",
        }}
      >
        <div className="absolute h-64 h-full w-full">
          <Img
            className="object-cover h-full w-full"
            fluid={data.italianAffair.childImageSharp.fluid}
          />
        </div>
        <div
          css={{
            content: '" "',
            position: "absolute",
            width: "100%",
            height: "100%",
            display: "block",
            top: "0",
            left: "0",
            backgroundColor: "rgba(62, 178, 175, 0.95);",
          }}
        ></div>
        <div className="bookDetails w-full h-full justify-center align-middle flex flex-col">
          <h2 className="z-50 w-full text-4xl mb-6 font-semibold text-center text-white">
            An Italian Affair
          </h2>
          <span className="inline-block z-50 w-full px-6 text-xl text-center text-white">
          A sweeping tale of love, betrayal and war...{" "}
          </span>
          <div className="z-50 flex flex-row justify-center mt-4">
            <Link
              to="/an-italian-affair"
              className="inline-block text-white hover:text-black z-50 mr-12"
              css={{
                transition: "0.7s all",
              }}
            >
              Learn more >
            </Link>
            <a
              href={config.buylinks.italianLink}
              target="_blank"
              rel="noopener noreferrer"
              className="inline-block text-white hover:text-black z-50"
              css={{
                transition: "0.7s all",
              }}
            >
              Buy now >
            </a>
          </div>
          <animated.div
            style={zoomOne}
            onMouseEnter={() => setLinkActiveOne(true)}
            onMouseLeave={() => setLinkActiveOne(false)}
            className="z-50 flex flex-row justify-center mt-16 mx-auto w-40 h-auto z-50 cursor-pointer"
          >
            <Link to="/an-italian-affair" className="block w-full h-full">
              <Img
                className="object-cover z-50 w-full h-full"
                css={{
                  boxShadow: "-3px 3px 5px rgba(0 ,0, 0, 0.15);",
                }}
                fluid={data.italianAffairThumb.childImageSharp.fluid}
              />
            </Link>
          </animated.div>
        </div>
      </div>


      <div
        className="relative w-full md:w-1/2 mb-4 md:mb-0 md:mr-2 overflow-hidden z-auto"
        css={{
          height: "630px",
        }}
      >
        <div className="absolute h-64 h-full w-full">
          <Img
            className="object-cover h-full w-full"
            fluid={data.parisSecret.childImageSharp.fluid}
          />
        </div>
        <div
          css={{
            content: '" "',
            position: "absolute",
            width: "100%",
            height: "100%",
            display: "block",
            top: "0",
            left: "0",
            backgroundColor: "rgba(28, 177, 233, 0.95);",
          }}
        ></div>
        <div className="bookDetails w-full h-full justify-center align-middle flex flex-col">
          <h2 className="z-50 w-full text-4xl mb-6 font-semibold text-center text-white">
            A Paris Secret
          </h2>
          <span className="inline-block z-50 w-full px-6 text-xl text-center text-white">
            A powerful promise. A terrible sacrifice. A second chance...{" "}
          </span>
          <div className="z-50 flex flex-row justify-center mt-4">
            <Link
              to="/a-paris-secret"
              className="inline-block text-white hover:text-black z-50 mr-12"
              css={{
                transition: "0.7s all",
              }}
            >
              Learn more >
            </Link>
            <a
              href={config.buylinks.parisLink}
              target="_blank"
              rel="noopener noreferrer"
              className="inline-block text-white hover:text-black z-50"
              css={{
                transition: "0.7s all",
              }}
            >
              Buy now >
            </a>
          </div>
          <animated.div
            style={zoom}
            onMouseEnter={() => setLinkActive(true)}
            onMouseLeave={() => setLinkActive(false)}
            className="z-50 flex flex-row justify-center mt-16 mx-auto w-40 h-auto z-50 cursor-pointer"
          >
            <Link to="/a-paris-secret" className="block w-full h-full">
              <Img
                className="object-cover z-50 w-full h-full"
                css={{
                  boxShadow: "-3px 3px 5px rgba(0 ,0, 0, 0.15);",
                }}
                fluid={data.parisSecretThumb.childImageSharp.fluid}
              />
            </Link>
          </animated.div>
        </div>
      </div>


    </div>
  )
}

export default SpanishBottom
